import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';
import MenuItem from "@material-ui/core/MenuItem";

const ExtendedChoiceField = forwardRef((props, ref) => {
  const { field: f, data: initial, autoFocus, errors, onChange } = props;
  const inputRef = useRef();
  const [error, setError] = useState(function (){
    const optionsLower = f.options.map((v)=>v.toLowerCase())
    if (initial.extended && initial.extended[f.name]){
      if(optionsLower.indexOf(initial.extended[f.name].toLowerCase()) === -1){
        return "Поле имеет значение '" + initial.extended[f.name] + "' и такого значения нет в списке возможных"
      }
      return ""
    }
  })
  useImperativeHandle(ref, () => ({
    fillValue: data => {
      data.extended = data.extended || {};
      if (inputRef.current.value){
        data.extended[f.name] = inputRef.current.value.trim();
      }else {
        data.extended[f.name] = "".trim();
      }
      return true;
    }
  }));

  const hasChoice = () =>{
    if (f.options.length === 1 && f.options[0] === "" ){
      return false
    }
    if (!f.options){
      return false
    }
    return true
  }
  const setDefaultValue =()=>{
    if (initial.extended && initial.extended[f.name]){
      if (error){
        return ""
      }
      return initial.extended[f.name]
    }
    return ""
  }


  return (
    <>
      <TextField
        select
        name={f.name}
        label={f.label}
        required={f.required}
        inputRef={inputRef}
        fullWidth
        margin='dense'
        variant='outlined'
        autoComplete='off'
        autoFocus={autoFocus}
        helperText={errors['extended.' + f.name]}
        error={!!errors['extended.' + f.name]}
        onChange={e => onChange(e.target.value, inputRef)}
        defaultValue={setDefaultValue()}
        disabled={!hasChoice()}
      >
        {f.options.map(it => (
          <MenuItem key={it.toLowerCase()} value={it.toLowerCase()}>
            {it}
          </MenuItem>
        ))}
      </TextField>
      <div style={{color:"red", fontSize:"12px"}}>{error}</div>
    </>
  );
});

export default ExtendedChoiceField;
