import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { TextField } from '@material-ui/core';

const ExtendedTextField = forwardRef((props, ref) => {
  const { field: f, data: initial, autoFocus, errors, onChange } = props;
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    fillValue: data => {
      data.extended = data.extended || {};
      data.extended[f.name] = inputRef.current.value.trim();
      return true;
    }
  }));

  return (
    <TextField
      name={f.name}
      label={f.label}
      required={f.required}
      inputRef={inputRef}
      fullWidth
      margin='dense'
      variant='outlined'
      autoComplete='off'
      autoFocus={autoFocus}
      helperText={errors['extended.' + f.name]}
      error={!!errors['extended.' + f.name]}
      onChange={e => onChange(e.target.value, inputRef)}
      defaultValue={(initial.extended && initial.extended[f.name]) || f.defaultValue}
    />
  );
});

export default ExtendedTextField;
