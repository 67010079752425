import {SystemFieldType, VisitorStatusType} from 'api/api';

const systemFields = Object.values(SystemFieldType).map(field => (field === 'group' ? 'groupName' : field));

export const getFieldFromVisitor = (visitor) => {
  let fields = {};
  for (let systemField of systemFields) {
    if (visitor[systemField]) {
      fields[systemField] = visitor[systemField]
    }
  }
  fields = {...fields, ...visitor.extended}
  
  return fields;
}

const checkField = (field, fieldName, p) => {
  if (!field || !p) {
    return false;
  }

  field = field.toString();

  if (fieldName === 'firstName' ||
    fieldName === 'lastName' ||
    fieldName === 'middleName' ||  
    p.charAt(0).toUpperCase() === p.charAt(0)
  ) {
    return field.toLowerCase().startsWith(p.toLowerCase()) || field.toLowerCase().indexOf(' ' + p.toLowerCase()) !== -1;
  } else {
    return field.toLowerCase().indexOf(p.toLowerCase()) !== -1;
  }
};

const filter = (originalVisitors, search, group, isReception) => {
  let visitors = originalVisitors;

  if (isReception && (!search || search.length < 3)) {
    return [];
  }

  if (!(!group || group === '')) {
    visitors = originalVisitors.filter(e => e.groupId === group);
  }

  if (!search || search === '') {
    return visitors;
  }

  if (search.startsWith('code:')) {
    const code = (search.startsWith('code:') ? search.substr(5) : search).toLowerCase()
    let res = [];
    for (let v of visitors) {
      if (v.qrToken.toLowerCase() === code) {
        res.push(v);
      }
    }
    return res;
  }

  if (Object.values(VisitorStatusType).includes(search)) {
    let res = [];
    for (let v of visitors) {
      if (v.status.status === search) {
        res.push(v);
      }
    }
    return res;
  }

  // Разбиваем search запрос на части и ищем совпадения в разных полях
  const parts = search.split(' ').filter(e => e !== '');
  return visitors.filter(visitor => {
    // Проверяем все части запроса. Каждая часть должна присутствовать в посетителе, хотя бы и в разных полях
    for (let part of parts) {
      // проверим все системные поля
      let found = false;
      for (let systemField of systemFields) {
        if (visitor[systemField]) {
          if (checkField(visitor[systemField], systemField, part)) {
            found = true;
            break;
          }
        }
      }
      if (found) {
        continue
      }

      // затем все расширенные
      if (!visitor.extended) {
        // если объект extended нет, то и проверять нечего, и можно сразу возвращать false
        return false
      }
      for (let extField in visitor.extended) {
        if (checkField(visitor.extended[extField], extField, part)) {
          found = true;
          break;
        }
      }
      if (!found) {
        return false
      }
    }
    return true
  });
};

export default filter;
