import {makeStyles} from "@material-ui/styles";
import {Checkbox, Link, Paper, Typography} from "@material-ui/core";
import {Link as RouterLink} from "react-router-dom";
import React from "react";
import useTheme from "@material-ui/core/styles/useTheme";

const useStyles = makeStyles(theme => ({
  policy: {
    display: 'flex',
    alignItems: 'center',
  },
  policyCheckbox: {
    marginTop: '14px'
  },
}));

export const PolicyForRegForm = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.policy}>
      <Checkbox
        checked={props.value || false}
        className={classes.policyCheckbox}
        color='primary'
        name='policy'
        onChange={props.onChange}
        required={true}
      />
      <Typography style={{marginTop:theme.spacing(2)}} color='textSecondary' variant='body1'>
        {props.tt.acceptPolicy1}&nbsp;
        <Link
          color='primary'
          component={RouterLink}
          to='/docs/privacy-policy.pdf'
          underline='always'
          variant='h6'
          target={'_blank'}
        >
          {props.tt.acceptPolicy2}
        </Link>
        .
      </Typography>
    </div>
  )
}
