import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {Button, Checkbox, TextField} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React, {useContext} from "react";
import {makeStyles} from "@material-ui/styles";
import CardBlock from "../../../components/CardBlock/CardBlock";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {MeetupContext} from "../../../MeetupContext";
import {Link, useParams} from "react-router-dom";
import {Link as LinkCore} from "@material-ui/core";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import {RegFormType, SystemFieldType} from "../../../api";
import Radio from "@material-ui/core/Radio";
import {useForm} from "react-hook-form";
import {ECTextField} from "../../../components/Forms/ECTextField";
import {ECRadioGroupField} from "../../../components/Forms/ECRadioGroupField";
import {fieldKey} from "../../VisitorList/components/ImportXLS/import_utils";
import {ECMultiCheckboxField} from "../../../components/Forms/ECMultiCheckboxField";
import * as _ from "underscore";

const useStyles = makeStyles(theme => ({
  groupLabel: {
    paddingTop: 12 //theme.spacing(1)
  }
}));

const RegFormSettings = props => {
  const { className, fields, regForm, save, ...rest } = props;
  const { mid: meetupId } = useParams();

  const classes = useStyles();
  const form = useForm();

  const handleSave = (data) => {
    save(data, form)
  }

  return (
    <CardBlock title={"Настройка формы регистрации"} form={form} save={handleSave} hint={
      <LinkCore href="https://docs.eventos42.ru/kak-nastroit-otpravku-dannykh-so-svoei-formy-registracii" target={'_blank'}>
        Как настроить отправку данных из своей формы?</LinkCore>
    }>
      <Grid container spacing={3}>
        {/*<Grid xs={4} className={classes.groupLabel}>*/}
        {/*  <FormLabel component="legend">Тип формы:</FormLabel>*/}
        {/*</Grid>*/}
        {/*<Grid xs={8}>*/}
        {/*  <ECRadioGroupField row name={"formType"} form={form} initial={regForm} options={[*/}
        {/*    {id:RegFormType.Flat, name:"Плоский"},*/}
        {/*    {id:RegFormType.Bar, name:"Квадратный"},*/}
        {/*  ]}/>*/}
        {/*</Grid>*/}

        <Grid xs={4} className={classes.groupLabel}>
          <FormLabel component="legend">Язык:</FormLabel>
        </Grid>
        <Grid xs={8}>
          <ECRadioGroupField row name={"language"} form={form} initial={regForm} options={[
            {id:"ru", name:"ru"},
            {id:"en", name:"en"},
          ]}/>
        </Grid>

        <Grid xs={4} className={classes.groupLabel}>
          <Typography>Заполняемые поля:</Typography>
        </Grid>
        <Grid xs={8}>
          <ECMultiCheckboxField name={"fieldsToHide"} form={form} initial={regForm}
                                invert={true}
                                options={fields.filter(f => f.systemField !== SystemFieldType.QrToken).map(f => {return {id:fieldKey(f), name:f.label}})}
          />
          <Typography>Поля добавляются в <Link style={{ cursor: 'pointer' }} to={`/m/${meetupId}/settings`}>Настройках полей</Link></Typography>
        </Grid>

        <Grid xs={4} className={classes.groupLabel}>
          <Typography>Сообщение об успешной регистрации:</Typography>
        </Grid>
        <Grid xs={8}>
          <ECTextField label='' name='completionText' initial={regForm} form={form}
                       multiline
                       rows={4}
          />
        </Grid>

      </Grid>
    </CardBlock>
  )

}

export default RegFormSettings;
