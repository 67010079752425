import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import { TextField } from '@material-ui/core';

const SystemEmailField = forwardRef((props, ref) => {
  const { field: f, data: initial, autoFocus, errors, onChange } = props;
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    fillValue: data => {
      data[f.systemField] = inputRef.current.value.trim();
      return true;
    },
  }));

  return (
    <TextField
      name={f.systemField}
      label={f.label}
      required={f.required}
      inputRef={inputRef}
      fullWidth
      margin='dense'
      variant='outlined'
      autoComplete='off'
      autoFocus={autoFocus}
      helperText={errors[f.systemField]}
      error={!!errors[f.systemField]}
      onChange={e => onChange(e.target.value, inputRef)}
      defaultValue={initial[f.systemField] || f.defaultValue}
    />
  );
});

export default SystemEmailField;
