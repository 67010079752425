import React from 'react';
import {Link as RouterLink, useParams} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
  },
}));

const Topbar = props => {
  const { className, ...rest } = props;
  const theme = useTheme();
  const classes = useStyles();
  const { token } = useParams();
//
  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color={theme.appBarColor} position='fixed'>
      <Toolbar>
          <RouterLink to='/'>
            {token === '7f00d7a5-402a-4437-8052-8b69babc51b0' ?
              <img alt='Logo' src={"/images/custom1/1.svg"} width={'100%'}/>
              :
              <img alt='Logo' src={theme.topLogo} width={theme.topLogoWidth || '100%'}/>
            }
          </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
