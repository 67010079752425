const fieldsIsEqual  = (a, b) => {
  if (a.indexOf(",") === -1) {
    return a.toString().toLowerCase()  === b.toString().toLowerCase()
  } else {
    return a.toString().toLowerCase().indexOf(b.toString().toLowerCase()) !== -1
  }
}

export function checkVisitor(visitor, deskSettings) {
  let result = !deskSettings.andOr

  for (let condition of deskSettings.conditions) {
    if (!condition.field) {
      continue;
    }
    let f = condition.field
    if (condition.field === 'group') {
      f = 'groupName'
    }
    let fieldValue = visitor[f] || visitor.extended[f];
    console.log(f, fieldValue, visitor)
    if (!fieldValue) {
      return false
    }
    let fieldResult = fieldsIsEqual(fieldValue, condition.value)
    if (condition.operator === 'notEqual') {
      fieldResult = !fieldResult
    }
    // название перепутано. тут false означает and и true означает or
    if (deskSettings.andOr ) {
      result |= fieldResult
    } else {
      result &= fieldResult
    }
  }

  let accepted = result
  if (deskSettings.accessReject) {
    accepted = !accepted
  }

  return accepted
}
