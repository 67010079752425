import React, {forwardRef, useCallback, useImperativeHandle, useRef, useState} from 'react';
import {Checkbox, Input, ListItemText, Select, TextField} from '@material-ui/core';
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import clsx from "clsx";
import Tooltip from "@material-ui/core/Tooltip";
import FormHelperText from "@material-ui/core/FormHelperText";
import {makeStyles} from "@material-ui/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const useStyles = makeStyles(theme => ({
  fixLabel: {
    transform: "translate(14px, 12px) scale(1)"
  },
}))

const ExtendedMultiChoiceField = forwardRef((props, ref) => {
  const { field: f, data: initial, autoFocus, errors, onChange } = props;
  const [labelWidth, setLabelWidth] = useState(70);
  const [error , setError] = useState()
  const inputRef = useRef();
  const classes = useStyles();
  const takeDefaultValue =()=>{
    if (initial.extended && initial.extended[f.name]){
      const defaultValue = initial.extended[f.name].split(",")
      const a = []
      const b = []
      defaultValue.map(t =>{
        if (f.options.indexOf(t) !== -1){
          a.push(t)
        }else{
          b.push(t)
        }
      })
      if(b.length > 0){
        setError("Этих полей больше не существует " +b.join(", "))
      }
      return a
    }
    return []
  }

  const [selectedOptions, setSelectedOptions] = useState(takeDefaultValue)
  const selectedOptionsLower = selectedOptions.map((v) => v.toLowerCase())

  useImperativeHandle(ref, () => ({
    fillValue: data => {

      data.extended = data.extended || {};
      if (inputRef.current.value){
        data.extended[f.name] = inputRef.current.value.join(',').trim();
      }else {
        data.extended[f.name] = "".trim();
      }

      return true;
    }
  }));

  const handleChange = (event) => {

    onChange(event.target.value, inputRef)
    const {
      target: { value },
    } = event;
    setSelectedOptions(
      typeof value === 'string' ? value.split(',') : value,
    );

  };

  const hasChoice = () =>{

    if (f.options.length === 1 && f.options[0] === "" ){
      return false
    }
    if (!f.options){
        return false
    }
      return true
  }

  const measuredRef = useCallback(node => {
    if (node !== null) {
      setLabelWidth(node.getBoundingClientRect().width + 14);
    }
  }, []);

  return (
    <>
          <FormControl variant="outlined" fullWidth style={{marginTop: 8}}>
            <InputLabel ref={measuredRef} className={clsx(selectedOptions.length === 0 && classes.fixLabel)}>
              {f.label}
            </InputLabel>
              <Select
                multiple
                inputRef={inputRef}
                value={selectedOptions}
                onChange={handleChange}
                input={
                  <OutlinedInput labelWidth={labelWidth} style={{height: 40}}/>
                }
                renderValue={(selected) => selected.join(', ')}
                disabled={!hasChoice()}
                error={!!errors['extended.' + f.name]}
                // defaultValue={takeDefaultValue() || ""}
              >
                {f.options.map(it => (
                  <MenuItem key={it.toLowerCase()} value={it.toLowerCase()} style={{}}>
                    <Checkbox checked={selectedOptionsLower.indexOf(it.toLowerCase()) > -1}/>
                    <ListItemText primary={it}/>
                  </MenuItem>
                ))
                }
              </Select>
            <div style={{color:"red", fontSize:"12px"}}>{error}</div>
          </FormControl>
    </>
  );

});

export default ExtendedMultiChoiceField;
