import React, { useContext, useEffect, useState } from 'react';
import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../AppContext';
import { useParams } from 'react-router-dom';
import MailingEditForm from './MailingEditForm';
import { useForm } from 'react-hook-form';
import { errorsToForm } from '../../../components/Forms/form_utils';
import {fixDotProperties} from "../../Merch/components/MerchEdit";
import {NewsStatusType} from "../../../api";

const MailingEdit = props => {
  const appContext = useContext(AppContext);
  const { mid: meetupId, mailingId } = useParams();
  const { goBack } = props;
  const form = useForm();

  const [initial, setInitial] = useState(undefined);
  useEffect(() => {
    appContext.mailingApi.getMailing(meetupId, mailingId).then(({ data }) => {
      if (Array.isArray(data.recipients.tags)) {
        data.recipients.tagsStr = data.recipients.tags.join(", ");
      }
      setInitial(data);
      if (data.status !== NewsStatusType.Draft) {
        form.setError('', "ALREADY_PUBLISHED", "Редактировать можно только рассылки в статусе Черновик")
      }
    });
  }, [meetupId, mailingId]);

  const handleSave = () => {
    let data = form.getValues();
    data = fixDotProperties(data);

    data.type = initial.type;
    data.meetupId = meetupId;
    data.mailingId = mailingId;
    data.recipients.tags = data.recipients.tagsStr && data.recipients.tagsStr.split(",") || []

    appContext.mailingApi
      .updateMailing(meetupId, mailingId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(errorsToForm(form));
  };

  if (!initial) return null;

  const title = {
    "news": "Редактирование рассылки",
    "notification": "Редактирование нотификации",
    "question": "Редактирование опроса"
  }
  return (
    <ModalDialog title={title[initial.type]} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
      <MailingEditForm meetupId={meetupId} form={form} initial={initial} type={initial.type} />
    </ModalDialog>
  );
};

MailingEdit.propTypes = {};

export default MailingEdit;
