import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import {Card, Typography, Avatar, Button, Hidden} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import clsx from 'clsx';

import filter from 'common/filterVisitors';
import { AppContext } from 'AppContext'
import { ScannerEventContext } from 'ScannerEventContext'
import {Link as RouterLink, Route} from "react-router-dom";
import QrReader from "react-qr-reader";
import VisitorAdd from "../../../VisitorList/components/VisitorAdd/VisitorAdd";
import RegistrationModal from "./RegistrationModal";

const useStyles = makeStyles(theme => ({
  deskContainer: {
    width: '100%',
    height: '100%',
    zIndex: 1000000,
    paddingTop: 64,
    ["@media (max-width:700px)"] : {
      paddingTop: 56,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  deskCardOuter: {
    width: 500,
    height: 620,
    ["@media (max-width:700px)"] : {
      width: '100%',
      height: '100%',
      borderRadius: 0
    },
  },

  deskCard: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    width: '100%',
    height: '100%',
    ["@media (max-width:700px)"] : {
      borderRadius: 0
    },
  },
  deskTopBlock: {
    width: '100%',
    height: 64,
   
    color: 'white',
    backgroundColor: 'grey',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0
  },

  deskTopBlock_allow: {
    backgroundColor:  '#4CAF50'
  },

  deskTopBlock_deny: {
    backgroundColor: '#E83C53'
  },

  visitorInfoContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ["@media (max-width:700px)"] : {
      width: '100%'
    },
  },

  visitorInfoContainer2: {
    ["@media (max-width:700px)"] : {
      width: '100%'
    },
  },

  topInfo: {
    fontSize: 20,
    fontWeight: 500,
    textAlign: 'center',
    width: 400,
    height: 64,
    marginBottom: 32,
    ["@media (max-width:700px)"] : {
      width: '100%',
      marginTop: 16,
      marginBottom: 16,
    },

  },

  visitorInfo: {
    fontSize: 28,
    fontWeight: 500,
    textAlign: 'center',
    width: 400,
    // height: 64,
    marginTop: 16,
    marginBottom: 16,
    ["@media (max-width:700px)"] : {
      width: '100%',
      marginTop: 16,
      marginBottom: 16,
    },
  },
  visitorInfo__group: {
    marginTop: 0,
    marginBottom: 16,
    fontSize: 24,
    fontWeight: 400,
    textAlign: 'center',
    width: '100%'
  },
  registrationButton: {
    fontSize: 20,
    textAlign: 'center',
    width: 400,
    height: 64,
    ["@media (max-width:700px)"] : {
      width: '100%',
      borderRadius: 0
    },
  }

}));

const SelfServiceDesk = ({receptionData, visitors, setPrintVisitors}) => {
	const classes = useStyles();
	const appContext = useContext(AppContext);
	const scannerContext = useContext(ScannerEventContext);
  const [visitor, setVisitor] = useState();
  const [error, setError] = useState();

	let timerId;

	function setInfoTimer() {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      setVisitor(null);
      setError(null);
    }, 7000);
  }

  const printBadge = visitorId => {
    setPrintVisitors([{ visitorId: visitorId }]);
  };

  function handleVisitor(visitor) {
    const meetupId = receptionData.meetup.meetupId;

    setVisitor(visitor);
    setInfoTimer();
    new Audio('/sounds/beep-short.mp3').play();

    appContext.visitorApi.checkin(meetupId, visitor.visitorId)
    if (receptionData.deskSettings.actionPrintBadgeEnabled) {
      printBadge(visitor.visitorId);
    }
	}

	const handleCodeFromScanner = code => {
    let fv = filter(visitors, 'code:' + code);
    if (fv.length === 1) {
      handleVisitor(fv[0])
    } else {
      setError("Посетитель не найден");
      setInfoTimer();
    }
  };

  const handleQrScan = code => {
    if (code) {
      if (!visitor || code !== visitor.qrToken) {
        let fv = filter(visitors, 'code:' + code);
        if (fv.length === 1) {
          handleVisitor(fv[0]);
        } else {
          setError("Посетитель не найден");
          setVisitor({firstName:code, lastName:""});
          setInfoTimer();
        }
      }
    }
  };
  const handleQrError = err => {
    setError(err);
    setInfoTimer();
  };


  useEffect(() => {
    scannerContext.addListener(handleCodeFromScanner);

    return (
      () => {
        scannerContext.removeListener(handleCodeFromScanner);
      }
    )
  }, []);

	return (
		<div className={classes.deskContainer}>
      <div className={classes.deskCardOuter}>
        <Card className={classes.deskCard}>
          <div
            className={clsx({
              [classes.deskTopBlock]: true,
              [classes.deskTopBlock_allow]: visitor && !error,
              [classes.deskTopBlock_deny]: error
            })}
          >
            <Typography style={{color: 'inherit', fontSize: 20}}>
            {
              error ? (
                <span style={{ display: 'inline-flex', alignItems: 'center'}}>
                  <CheckCircleOutlineIcon style={{marginRight: 5}}/>
                  <span>{error}</span>
                </span>
                ) : (visitor ? (
                <span style={{ display: 'inline-flex', alignItems: 'center'}}>
                  <CheckCircleOutlineIcon style={{marginRight: 5}}/>
                  <span>Посетитель найден. Проходите.</span>
                </span>
                ) : (
                'Ожидание сканирования'
              ))
            }
            </Typography>
          </div>
          <div className={classes.visitorInfoContainer}>
            <div className={classes.visitorInfoContainer2}>
              { visitor ? (
                <>
                  <Typography
                    className={classes.visitorInfo}
                  >
                    {visitor.firstName + " " + visitor.lastName}
                  </Typography>
                  <Typography
                    className={classes.visitorInfo__group}
                  >
                    {visitor.groupName}
                  </Typography>
                </>
                ) : (
                <Typography
                  className={classes.topInfo}
                >
                  Поднесите к зоне сканирования билет со штрих-кодом, который вы получили после регистрации.
                </Typography>
              )
              }

              { (visitor && visitor.photo) || !receptionData.deskSettings.webCamBarScannerEnabled ? (
                <Avatar
                  // src={noAvatar}
                  style={{width: 270,height:270,margin: '0 auto 85px'}}
                  onClick={() => {
                    setVisitor(null);
                    setError(null);
                  }}
                >
                  {
                    visitor && visitor.photo && (
                      <img src={'/api/image/' + visitor.photo} style={{width: '100%'}}/>
                    )
                  }

                </Avatar>
              ) : (
              <div style={{width:300,height:300,margin:'0 auto 55px',backgroundColor:'#bdbdbd',borderRadius:4}}>
                <QrReader delay={300}  style={{ width: '300px' }}
                          facingMode={(receptionData.deskSettings.webCamNumber === 1 ? 'environment' : 'user')}
                          onError={handleQrError} onScan={handleQrScan} />
              </div>
              )}

              {receptionData.deskSettings.selfRegistrationEnabled && (
                <RouterLink to={'/reception/visitor/add'}>
                  <Button color='primary' variant='contained' className={classes.registrationButton}>
                    Зарегистрироваться
                  </Button>
                </RouterLink>
              )}

            </div>

          </div>
        </Card>

      </div>

      <Route path={'/reception/visitor/add'} exact>
        <RegistrationModal meetupId={receptionData.meetup.meetupId} goBack={(visitor) => {
          handleVisitor(visitor)
          appContext.history.push('/reception');
        }} />
      </Route>

    </div>

  );
}


export default SelfServiceDesk;
