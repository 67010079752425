import React, { useState, useContext, useEffect, useRef} from 'react';
import { useParams } from 'react-router-dom';
import JSZip from 'jszip'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import ModalDialog from 'components/ModalDialog/ModalDialog';
import { Typography, Button, TextField, MenuItem, Link, Modal, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AppContext } from 'AppContext'; 
import { MeetupContext } from 'MeetupContext'
import { getFieldFromVisitor } from 'common/filterVisitors'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Popover from '@material-ui/core/Popover';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getCrop } from 'views/VisitorList/components/VisitorEditForm/fields/SystemPhotoField'
import { resolve } from 'url';
import color from '@material-ui/core/colors/amber';
import {SystemFieldType} from "../../../../api";



const useStyles = makeStyles(theme => ({
  ol: {
    counterReset: 'counter',
    listStyle: 'none'
  },
  li: {
    counterIncrement: 'counter',
    '&::before': {
      content: 'counter(counter)',
      display: 'inline',
      marginRight: 20,
      height: 26,
      width: 26,
      fontSize: 20,
      fontFamily: 'Roboto, sans-serif',
      textAlign: 'center',
      lineHeight: '26px',
      borderRadius: 13,

      color: 'white',
      backgroundColor: '#B7B7B7'
    },
    display: 'flex'
  },
  li__title: {
    fontSize: 20,
    fontWeight: 500,
    color: '#213955',
    lineHeight: '24px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    overflow: 'scroll',
  },
  paper: {
    boxShadow: theme.shadows[5],
    margin: 'auto',
    padding: theme.spacing(2, 4, 2),
    width: 400,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));


const ImportPhoto = ({ visitors, backAndRefresh }) => {
  let zip = new JSZip();
  const classes = useStyles();

  const appContext = useContext(AppContext);
  const meetupContext = useContext(MeetupContext);
  const meetupId = useParams().mid;

  const [copied, setCopied] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const anchorEl = useRef();
  // const [zipArchive, setZipArcive = useState();

  const upload = e => {
    e.preventDefault();
    document.getElementById('upload-zip').click();
  };

  const [zipArchive, setZipArchive] = useState();
  const uploadZip = async (e) => {
    setZipArchive(e.target.files[0]);
      // appContext.imageApi.uploadImage(undefined, undefined, undefined, file).catch(console.log)
  };

  const [fieldName, setFieldName] = useState(meetupContext.fields[0].systemField || meetupContext.fields[0].name);
  const handleFieldNameChange = (e) => {
    setFieldName(e.target.value)
  }

  const proportion = (meetupContext.fields.find( f => f.systemField === SystemFieldType.Photo) || {}).photoProportion || "1x1";
  const aspect = proportion === '3x4' ? 3./4. : 1;

  const [matches, setMatches] = useState({
    data: [],
    countOk: 0,
    countError: 0,
    countSquared: 0,
    errors: []
  });
  useEffect(() => {
    
    const match = async () => {
      if (zipArchive && fieldName) {
        let archive = await zip.loadAsync(zipArchive);
        
        const newMatches = {
          data: [],
          countOk: 0,
          countError: 0,
          countSquared: 0,
          errors: []
        }
        let archiveFiles = [];
        archive.forEach((relativePath, file) => { archiveFiles.push(file)})
        for (let file of archiveFiles) {
          if (!file.dir) {
            console.log(file.name)
            let fileNameWithExt = file.name.slice(file.name.lastIndexOf('/') + 1);
            let fileName = fileNameWithExt.slice(0, fileNameWithExt.lastIndexOf('.'));
            let count = 0;
            let matchedVisitor
            for (let visitor of visitors) {
              let temp = fileName === 'groupName' ? 'group' : fieldName
              let visitorField = visitor[temp] || visitor.extended[temp];
              if (!visitorField) {
                continue;
              }
              visitorField = visitorField.toString();

              if (visitorField.toLowerCase() === fileName.toLowerCase()) {
                ++count;
                visitor.groupName = null
                matchedVisitor = visitor
              }
            }

            if (count === 1) {
              try {
                const blob = await file.async('blob')
                const { imageObj, crop } = await getImageObj(blob)
                newMatches.data.push({
                  visitor: matchedVisitor,
                  file: imageObj,
                  crop: crop
                })
                newMatches.countOk++;

                
                if (imageObj.naturalHeight !== imageObj.naturalWidth) {
                  ++newMatches.countSquared
                }
                console.log(file.name, ' -- ok ', newMatches)
              }
              catch (e) {
                console.log(e)
                newMatches.errors.push( { file: fileNameWithExt, error: e });
                newMatches.countError++;
                console.log(file.name, ' -- error')
              }
            } else {
              newMatches.errors.push( { file: fileNameWithExt, error: 'Не удалось сопоставить имя файла' });
              newMatches.countError++;
              console.log(file.name, ' -- error')
            }

            
          }
        }
        setMatches(newMatches)
      }
    }

    match();
    
  }, [zipArchive, fieldName])

  const getImageObj = (blob) => {
    return new Promise((resolve, reject) => {
    let image = new Image();
    image.src = URL.createObjectURL(blob)
      image.onload = function()  {
        const cropSize = Math.min(image.naturalHeight * aspect, image.naturalWidth);
        const y = (image.naturalHeight - cropSize / aspect) / 2;
        const x = (image.naturalWidth - cropSize) / 2;
        const initialCrop = {
          width: cropSize,
          height: cropSize / aspect,
          x,
          y
        }
        resolve({imageObj: image, crop: initialCrop})
      }
      image.onerror = () => reject('Ошибка во время обработки изображения(проверьте формат)')
    })
  }

  const uploadPhoto = async () => {
    let matched = [...matches.data];
    setLoading(true)
    console.log(matched)
    for (let i = 0; i < matched.length; ++i) {
      try {
        let match = {...matched[i]}

        console.log("crop", match.crop)
        match.file = await getCrop(match.file, match.crop, aspect)

        matched[i] = match
      } catch(err) {
        console.log(err)
      }
    }
    
    let reqs = matched.map(match => (
      appContext.imageApi.uploadImage(meetupId, match.visitor.visitorId, undefined, match.file)
        .then(({data}) => appContext.visitorApi.updateVisitor(meetupId, match.visitor.visitorId, {...match.visitor, photo: data.imageId} ))
    ))

    Promise.all(reqs)
      .then(() => {
        setLoading(false);
        backAndRefresh('refresh')
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message)
      });

  }
  
  return (
    <ModalDialog 
      title='Импорт фотографий'
      handleClose={() => appContext.history.push('/m/' + meetupId +'/visitor')}
      noButtons
    >
        <ol className={classes.ol}>
          <li className={classes.li}>
            <div>
              <Typography className={classes.li__title}>Загрузите zip-архив с фотографиями посетителей</Typography> 
              <Typography 
                style={{
                  fontSize: 12,
                  lineHeight: '16px',
                  fontWeight: 500,
                  color: 'rgba(38,50,56,0.6)',
                  margin: '10px 0 30px 0'
                }}
              >
                Фото должно быть квадратное, в формате png или jpg, максимальный размер фото 4 Мб, максимальное разрешение 1200х1200.
              </Typography>
              <div style={{display: "flex", alignItems: "center", marginBottom: 30}}>
                <Button 
                  onClick={upload}
                  color='primary'
                  variant='contained'
                  size='large'

                >
                    Выбрать файл
                </Button> 
                <span style={{
                    marginLeft: 20, 
                    fontFamily: 'Roboto',
                    fontSize: 14,
                  }}
                >
                  {zipArchive ? 'Файл: ' + zipArchive.name: ''}
                </span>
              </div>
              <input style={{ display: 'none' }} accept='application/zip' id='upload-zip' type='file' onChange={uploadZip} />
            </div>
          
        
          </li>
          <li className={classes.li}>
            <div>
              <Typography className={classes.li__title}>Выберите поле, с которым надо сопоставить имя фотографии</Typography>
              <TextField
                select
                value={fieldName}
                onChange={handleFieldNameChange}
                variant='outlined'
                style={{width: 208, margin: '35px 0 35px 0', height: 45}}
                margin='dense'
              >
                {
                  meetupContext.fields.map((field) => {
                    let name = field.systemField || field.name;
                    return (
                      <MenuItem key={name} value={name}>
                        {field.label}
                      </MenuItem>
                    )
                  })
                }
              </TextField>
            </div>
          </li>
          <li className={classes.li}>
            <div>
              <Typography className={classes.li__title}>Результат сопоставления</Typography>
                {
                  false && (!zipArchive || !fieldName ) ? (
                    'Загрузите архив и выберете фото для сопоставления'
                  ) : (
                  <div>
                    {
                      matches.countOk > 0 && (
                        <div style={{display: 'flex', alignItems: 'center', fontFamily: 'Roboto', margin: '10px 0'}}> 
                          <CheckCircleOutlineIcon style={{color: '#4CAF50', width: 23, height: 23, marginRight: 10}}/> 
                          <span>
                            {matches.countOk} фото будут загружены
                            {
                              matches.countSquared > 0 && `(${ matches.countSquared} будут сделаны квадратными)`
                            } 
                            
                          </span>
                        </div>
                      )
                    } 
                    {
                      matches.countError > 0 && (
                        <div style={{display: 'flex', alignItems: 'center', fontFamily: 'Roboto', margin: '10px 0'}}> 
                          <HighlightOffIcon style={{color: '#E83C53', width: 23, height: 23, marginRight: 10}}/> 
                          <span>
                            {matches.countError} фото не удалось сопоставить (
                            <CopyToClipboard text={matches.errors.map(err => `${err.file}: ${err.error}`).join('\n')} onCopy={() => setCopied(true)} >
                              <Link color='primary' underline='always' style={{ cursor: 'pointer' }}  ref={anchorEl} >
                              скопировать имена ошибочных фото
                              </Link>
                            </CopyToClipboard>)
                          </span>
                          {copied && (
                            <Popover
                              open={true}
                              anchorEl={anchorEl.current}
                              onClose={() => setCopied(false)}
                              anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                              transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                            >
                              <Typography>Лог скопирован</Typography>
                            </Popover>
                          )}
                        </div>
                      )
                    } 
                  </div>
                  )
                }
            </div>
          </li>
        </ol>
        <Button 
          style={{margin: '20px auto', display: 'block'}} 
          onClick={uploadPhoto}
          color='primary'
          variant='contained'
          size='large'
        >
          Загрузить
        </Button>
        <div style={{color: '#E83C53', fontFamily: 'Roboto', textAlign: 'center'}}>{error}</div>
        <Modal
          open={loading}
          className={classes.modal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Paper className={classes.paper}>
            <Typography component='span'>
              Идет обработка данных...
            </Typography>
    
            <CircularProgress />
          </Paper>
        </Modal>
    </ModalDialog>
  )
}

export default ImportPhoto;
