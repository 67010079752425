import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Avatar } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import clsx from 'clsx';

import filter from 'common/filterVisitors';
import { AppContext } from 'AppContext'
import { ScannerEventContext } from 'ScannerEventContext'
import { checkVisitor } from 'common/desks'
import QrReader from "react-qr-reader";

const useStyles = makeStyles(theme => ({
  receptionContainer: {
    width: '100%',
    height: '100%'
  },
  deskContainer: {
    width: '100%',
    height: '100%',
    zIndex: 1000000,
    paddingTop: 64,
    ["@media (max-width:700px)"] : {
      paddingTop: 56,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  deskCard: {
    width: 500,
    height: 620,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    ["@media (max-width:700px)"] : {
      width: '100%',
      height: '100%',
      borderRadius: 0
    },
  },
  accessReject: {
    width: '100%',
    height: 80,
   
    color: 'white',
    backgroundColor: 'grey',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0
  },

  accessReject_allow: {
    backgroundColor:  '#4CAF50'
  },

  accessReject_deny: {
    backgroundColor: '#E83C53'
  },

  visitorInfoContainer: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  visitorInfo: {
    flexGrow: 0,
  },
  
  visitorInfo__name: {
    fontSize: 30,
    fontWeight: 500,
    textAlign: 'center',
    width: '100%'
  },
  visitorInfo__group: {
    marginTop: 35,
    fontSize: 24,
    fontWeight: 400,
    textAlign: 'center',
    width: '100%'
  }
}));

const AccessControlDesk = ({receptionData, visitors}) => {
	const classes = useStyles();
	const appContext = useContext(AppContext)
	const scannerContext = useContext(ScannerEventContext)
  const [currentVisitor, setCurrentVisitor] = useState();
  const [access, setAccess] = useState();
  const [error, setError] = useState();

	let timerId;

  function handleControl(visitor) {
    const meetupId = receptionData.meetup.meetupId;

    setInfoTimer()

    let accepted = checkVisitor(visitor, receptionData.deskSettings)
  
    if (accepted) {
      appContext.visitorApi.writeDeskVisit(meetupId, visitor.visitorId);
    }
    setCurrentVisitor(visitor)
    setAccess(accepted);
	}

  function setInfoTimer() {
    clearTimeout(timerId);
    timerId = setTimeout(() => {
      setCurrentVisitor(null);
      setError(null);
    }, 3000);
  }

  const handleCodeFromScanner = code => {

    let fv = filter(visitors, 'code:' + code);
    if (fv.length === 1) {
      handleControl(fv[0])
    }
  };

  useEffect(() => {
    scannerContext.addListener(handleCodeFromScanner);

    return (
      () => {
        scannerContext.removeListener(handleCodeFromScanner);
      }
    )
  }, [])

  const handleQrScan = code => {
    if (code) {
      handleCodeFromScanner(code)
    }
  };
  const handleQrError = err => {
    alert(err)
    setError(err);
    setInfoTimer();
  };


	return (
		<div className={classes.deskContainer}>
      <Card className={classes.deskCard}>
        <div
          className={clsx({
            [classes.accessReject]: true,
            [classes.accessReject_allow]: currentVisitor && access,
            [classes.accessReject_deny]: currentVisitor && !access
          })}
        >
					<Typography style={{color: 'inherit', fontSize: 20}}>
					{
						!currentVisitor ? (
							<>
                {
                  error ? (
                    <span style={{ display: 'inline-flex', alignItems: 'center'}}>
                      <CheckCircleOutlineIcon style={{marginRight: 5}}/>
                      <span>{error}</span>
                    </span>
                  ) : (
                    'Ожидание сканирования'
                  )
                }
              </>
						) : (
							currentVisitor && access ? (
								<span style={{ display: 'inline-flex', alignItems: 'center'}}>
									<CheckCircleOutlineIcon style={{marginRight: 5}}/>
									<span>Доступ разрешен</span>
								</span>
							) : (
								<span style={{ display: 'inline-flex', alignItems: 'center'}}>
									<HighlightOffIcon style={{marginRight: 5}}/>
									Доступ запрещен
								</span>
							)
						)
						
					}
					</Typography>
        </div>
        <div className={classes.visitorInfoContainer}>
          <div>
            {currentVisitor ? (
              <Avatar
                style={{width: 240,height:240,margin: '0 auto 75px'}}
              >
                {
                  currentVisitor && currentVisitor.photo && (
                    <img src={'/api/image/' +   currentVisitor.photo} style={{width: '100%'}}/>
                  )
                }
              </Avatar>
            ) : receptionData.deskSettings.webCamBarScannerEnabled ? (
              <div style={{width:300,height:300,margin:'0 auto 55px',backgroundColor:'#bdbdbd',borderRadius:4}}>
                <QrReader delay={300}  style={{ width: '300px' }}
                          facingMode={(receptionData.deskSettings.webCamNumber === 1 ? 'environment' : 'user')}
                          onError={handleQrError} onScan={handleQrScan} />
              </div>
            ) : (
              <Avatar style={{width: 240,height:240,margin: '0 auto 75px'}}/>
            )}




            <Typography
              className={classes.visitorInfo__name}
            >
              {
                currentVisitor ? (
                  currentVisitor.firstName + " " + currentVisitor.lastName
                ) : (
                  "Имя Фамилия"
                )
              }
            </Typography>
            <Typography
              className={classes.visitorInfo__group}
            >
              {
                currentVisitor ? (
                  currentVisitor.groupName
                ) : (
                  "Группа"
                )
              }
            </Typography>
            
          </div>
        </div>
      </Card>
    </div>
  );
}


export default AccessControlDesk;
