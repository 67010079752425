import {makeStyles} from "@material-ui/styles";
import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Paper, Typography} from "@material-ui/core";
import VisitorEditForm from "../VisitorList/components/VisitorEditForm/VisitorEditForm";
import {SystemFieldType} from "../../api";
import {PolicyForRegForm} from "./PolicyForRegForm";

const useStyles = makeStyles(theme => ({
  paper: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    ["@media (max-width:700px)"] : {
      borderRadius: 0,
      height: '100%'
    },
  },
  space: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

}));

export const BarRegistrationForm = (props) => {
  const classes = useStyles();
  const {regFormData, formRef, handleSave, handleUpdate, handleCancel, errors, data, tt} = props;
  const [policyCheckbox, setPolicyCheckbox] = useState(false)

  console.log(data)
  return (
    <Paper className={classes.paper} style={{textAlign:"center"}}>
      {data.visitorId ? (
          <Typography variant={"h3"} className={classes.space}>{tt.editRegistration2} <br/> {regFormData.meetup.name}</Typography>
        )
      :
        (
          <Typography variant={"h3"} className={classes.space}>{tt.registrationFor} {regFormData.meetup.name}</Typography>
        )
      }

      <VisitorEditForm data={data} errors={errors} ref={formRef} fields={regFormData.fields.fields.filter(f => f.systemField !== SystemFieldType.QrToken)} groups={regFormData.groups}
                       fieldsToHide={regFormData.regForm.fieldsToHide}/>

      {data.visitorId ?
        (
          <>
            {data.status.status !== 'canceled' &&
              <Button color='secondary' variant='contained' onClick={() => handleCancel()} className={classes.space}>
                {tt.registrationCancelButton}
              </Button>
            }

            <Button color='primary' variant='contained' onClick={() => handleUpdate()} className={classes.space}>
              {data.status.status === 'canceled' ?
                <>{tt.registrationRestoreButton}</>
                :
                <>{tt.registrationUpdateButton}</>
              }
            </Button>
          </>
        )
        : (
          <>
            <PolicyForRegForm value={policyCheckbox} tt={tt} onChange={(e) => {
            setPolicyCheckbox(e.target.checked)
          }}/>

            <Button color='primary' variant='contained' onClick={() => handleSave()} className={classes.space} disabled={!policyCheckbox}>
              {tt.registrationCreateButton}
            </Button>
          </>
        )
      }

    </Paper>
  );
};
