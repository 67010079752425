import React, { useContext, useEffect, useState } from 'react';
import { Route, Link , Switch } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles';

import { Card, CardHeader, Button } from '@material-ui/core';

import { AppContext } from '../../../../AppContext';
import { shortenDate } from '../../../MeetupList/components/MeetupsTable/MeetupsTable';
import { ServicePlanActionType } from '../../../../api';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import BuyOptionsPack from '../BuyOptionsPack/BuyOptionsPack';
import BuyPackError from '../BuyPackError/BuyPackError';
import BuyRebillResult from '../BuyRebillResult/BuyRebillResult';
import UnkinkingCardConfirmation from '../UnlinkingCardConfirmation/UnlinkingCardConfirmation'
import ChangeTariffBasic from '../ChangeTariffBasic/ChangeTariffBasic'
import ChangeTariffStandart from '../ChangeTariffStandart/ChangeTariffStandart'
import ChangeTariffUltimate from '../ChangeTariffUltimate/ChangeTariffUltimate'
import Tariff from '../Tariff/Tariff'

import NfcIcon from '@material-ui/icons/Nfc';
import DomainIcon from '@material-ui/icons/Domain';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    fontFamily: 'Roboto, sans-serif',
    marginTop: 82,
  },
  card: {
    marginBottom: 82,
    color: '#37474F',
    padding: '0 48px 25px 48px',
    flex: '1 0 40%',
    boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.2), 0px 3px 16px rgba(0, 0, 0, 0.12), 0px 9px 12px rgba(0, 0, 0, 0.14)',

    '&:nth-child(2n + 1)': {
      marginRight: 112,
    },

    '&_tarif': {
      flex: '1 0 100%',
    }
  },
  card__header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    lineHeight: '66px',
    fontSize: 20,
    borderBottom: '3px solid #EEEEEE',
    fontWeight: 'normal',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  card__content: {
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  price: {
    fontSize: 20,
    marginBottom: 20,
  },
  description: {
    fontSize: 14,
    marginBottom: 33,
    lineHeight: '24px',
  },
  cardButton: {
    alignSelf: 'flex-end',
    width: 235,
  },
  td: {
    padding: '5px 0px',
    fontSize: 16,
  },
  note: {
    fontSize: 12,
    marginTop: 10,
  },
  link: {
    color: 'rgba(232, 60, 83, 0.7)',
    fontSize: 14,
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  tarifsContainer: {
    height: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  tariffIcon: {
    color: 'rgba(232, 60, 83, 0.7)', 
    height: 25,
    width: 25
  } 
}));


const ProfileAccountView = props => {
  const classes = useStyles();

 ;

  const { className, data, tariffs, setLogFilter, ...rest } = props;
  const servicePacks = {};
  tariffs.servicePacks.forEach(pack => {
    servicePacks[pack.name] = pack;
  });

  const tariffsInfo = [
    {
      id: 'basic',
      name: tariffs.servicePlans[1].publicName,
      icon: <NfcIcon className={classes.tariffIcon} />,
      price: tariffs.servicePlans[1].price + ' руб. + оплата стоек',
      description: ['1 мероприятие в месяц', '200 участников'],
      features: [
        'Онлайн и офлайн регистрация',
        'Чат-бот',
        'Редактор бейджей',
        'Контроль доступа в залы',
        'Статистика',
      ]
    },
    {
      id: 'ultimate',
      name: tariffs.servicePlans[3].publicName,
      icon: <DomainIcon className={classes.tariffIcon} />,
      price: tariffs.servicePlans[3].price + ' руб./месяц + оплата стоек',
      description: ['безлимит на мероприятия и кол-во участников'], 
      features: [
        'Тариф включает в себя весь набор опций из Базового тарифа',
      ]
    },
    {
      id: 'unlimited',
      name: tariffs.servicePlans[4].publicName,
      icon: <AllInclusiveIcon className={classes.tariffIcon} />,
      price: 'цена по запросу',
      description: ['безлимит на мероприятия и кол-во участников'], 
      features: [
        'White label - ваше название и логотип',
        'On-premise установка на ваши сервера',
        'Возможность доработок под ваше мероприятие'
      ]
    }
  ]
  const appContext = useContext(AppContext);

  const goBack = () => {
    appContext.history.push('/profile/account');
  }
  const goToLogs = (e) => {
    e.preventDefault();
    setLogFilter('payments');
    appContext.history.push('/profile/log')
  }

  const [cardData, setCardData] = useState({});
  useState(() => {
    appContext.accountApi.getCurrentCard(data.account.accountId)
      .then(res => {
        setCardData(res.data);
      })
      .catch(appContext.errorHandler);
  })

  const handleUnink = () => {
    appContext.accountApi.unlinkCard(data.account.accountId, cardData)
      .then(() => {
        setCardData({});
        goBack();
      })
      .catch(appContext.errorHandler)
  }

  const limitTitle = {
    meetups: 'Мероприятия',
    visitors: 'Посетители',
    sms: 'Рассылки писем',
    deskPerDay: 'Стойка регистрации *',
  };

  


  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <h1 className={classes.card__header}>
          <div>
            Ваш тарифный план: <span style={{ fontWeight: 'bold' }}>{data.servicePlan.publicName}</span>{' '}
          </div>
        </h1>
        <div className={classes.card__content}>
          <div>
            <div className={classes.price}>
              Стоимость: <span style={{ fontWeight: 'bold' }}>{data.servicePlan.price}</span> руб
            </div>
            <div className={classes.description}>{data.servicePlan.description}</div>
          </div>
          <Button variant='outlined' className={classes.cardButton} href='#tariffs'>
            Сменить тариф
          </Button>
        </div>
      </Card>
      <Card className={classes.card}>
        <h1 className={classes.card__header}>Лимиты</h1>
        <div className={classes.card__content}>
          <table style={{ width: '100%' }}>
            <tbody>
              {data.account.limits.map(limit => (
                <tr>
                  <td className={classes.td}>{limitTitle[limit.limit]}</td>
                  <td className={classes.td}>
                    <span style={{ fontWeight: 'bold' }}>{limit.current}</span>/{limit.max}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={classes.note}>* - докупается отдельно</div>
        </div>
      </Card>
      <Card className={classes.card}>
        <h1 className={classes.card__header}>
          <div>Карта для оплаты </div>
          <a className={classes.link} onClick={goToLogs}>
            История платежей
          </a>
        </h1>
        <div className={classes.card__content}>
          <div className={classes.description}>
            {
              cardData.cardNumber ? (
                <>
                  К аккаунту привязана карта с номером <span style={{fontWeight: 'bold'}}>{cardData.cardNumber}</span>
                </>
              ) : (
                <>Привязать карту к аккаунту можно при проведении платежа на покупку опций или оплаты тарифа.</>
              )
            }
          </div>
          {
            cardData.cardNumber  && 
            <Button variant='outlined' className={classes.cardButton} onClick={() => appContext.history.push('/profile/account/unlink-confirmation')}>
              Отвязать карту
            </Button>
          }
         
        </div>
      </Card>
      <Card className={classes.card}>
        <h1 className={classes.card__header}>Дополнительные опции</h1>
        <div className={classes.card__content}>
          <table style={{ width: '100%' }}>
            <tbody>
              {
                tariffs.servicePacks.map(pack => (
                  <tr>
                    <td className={classes.td}>
                      { pack.description }
                    </td>
                    <td className={classes.td}>
                      <span style={{ fontWeight: 'bold' }}>{pack.price} руб</span>
                    </td>
                    <td className={classes.td}>
                      <Link style={{ fontSize: 16 }} className={classes.link} to={'/profile/account/buy-pack/' + pack.name}>
                        Купить
                      </Link>
                    </td>
                  </tr> 
                ))
              }
            </tbody>
          </table>
        </div>
      </Card>
      <Card className={classes.card} style={{flex: '1 0 100%'}} id='tariffs'>
        <h1 className={classes.card__header}>Тарифы</h1>
        <div className={classes.tarifsContainer}>
          {
            tariffsInfo.map(t => <Tariff key={t.id} data={t} disabled={data.account.servicePlanId.slice(0, data.account.servicePlanId.indexOf('_')) === t.id}/>)
          }
        </div>
      </Card>
      <Route path='/profile/account/buy-pack/:name'>
        <BuyOptionsPack packs={servicePacks} cardNumber={cardData.cardNumber} accountId={data.account.accountId}/>
      </Route>
      <Route path='/profile/account/buy-pack-error/:name'>
        <BuyPackError />
      </Route>
      <Route path='/profile/account/unlink-confirmation'>
        <UnkinkingCardConfirmation handleOk={handleUnink} goBack={goBack}/>
      </Route>
      <Route path='/profile/account/buy-rebill-result/:result'>
        <BuyRebillResult />
      </Route>

      <Route path='/profile/account/change-tariff/basic'>
        <ChangeTariffBasic accountId={data.account.accountId} />
      </Route>
      <Route  path='/profile/account/change-tariff/standart'>
        <ChangeTariffStandart accountId={data.account.accountId} cardNumber={cardData.cardNumber} price={tariffs.servicePlans[2].price}/>
      </Route>
      <Route path='/profile/account/change-tariff/ultimate'>
        <ChangeTariffUltimate accountId={data.account.accountId} userId={data.account.owner.userId} />
      </Route>

    </div>
  );
};

export default ProfileAccountView;
