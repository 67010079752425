import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';

import { AppContext } from '../../AppContext';
import MailingToolbar from './components/MailingToolbar';
import MailingTable from './components/MailingTable';
import { Route, useParams } from 'react-router-dom';
import MailingAdd from './components/MailingAdd';
import MailingEdit from './components/MailingEdit';
import {MeetupContextProvider} from "../../MeetupContext";
import filter from "../../common/filterVisitors";
import {visitorsToCells} from "../VisitorList/components/ExportXLS/ExportXLS";
import XLSX from "xlsx";
import {SystemFieldType} from "../../api";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const MailingList = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);

  const { mid: meetupId } = useParams();

  const [refreshCounter, setRefresh] = useState(0);
  const [mailings, setMailings] = useState([]);

  useEffect(() => {
    appContext.mailingApi
      .listMailings(meetupId)
      .then(({ data }) => {
        setMailings(data)
      })
      .catch(appContext.errorHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetupId, refreshCounter]);

  const handleDelete = (meetupId, mailingId) => {
    appContext.mailingApi.deleteMailing(meetupId, mailingId).then(() => {
      setMailings(mailing => mailing.filter(u => u.mailingId !== mailingId));
    });
  };

  const backAndRefresh = data => {
    appContext.history.push('/m/' + meetupId + '/mailing');
    if (data) {
      setRefresh(refreshCounter + 1);
    }
  };

  return (
    <div className={classes.root}>
      <MailingToolbar meetupId={meetupId} refresh={() => setRefresh(refreshCounter + 1)} />
      <MailingTable
        meetupId={meetupId}
        mailings={mailings}
        handleDelete={handleDelete}
      />

      <Route path={'/m/:mid/mailing/add'} exact>
        <MeetupContextProvider meetupId={meetupId}>
          <MailingAdd goBack={backAndRefresh} />
        </MeetupContextProvider>
      </Route>

      <Route path={'/m/:mid/mailing/id/:mailingId'} exact>
        <MeetupContextProvider meetupId={meetupId}>
          <MailingEdit goBack={backAndRefresh} />
        </MeetupContextProvider>
      </Route>
    </div>
  );
};

export default MailingList;
