import React, { forwardRef, useContext, useImperativeHandle, useRef, useState, useCallback, Fragment } from 'react';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import { MeetupContext } from '../../../../../MeetupContext';
import { AppContext } from '../../../../../AppContext';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import Modal from '@material-ui/core/Modal';
import Webcam from 'react-webcam';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import {RegFormContext} from "../../../../RegForm/RegForm";

const useStyles = makeStyles(theme => ({
  divider: {
    height: theme.spacing(1),
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  image: {
    maxWidth: '300px',
    maxHeight: '300px',
    objectFit: 'contain',
  },
}));

const screenShotSize = 350;

export const getCrop = (image, crop, aspect, cropFromBlob = () => {}) => {

  if (image && crop.width && crop.height) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / (image.width || image.naturalWidth );
    const scaleY = image.naturalHeight / (image.height || image.naturalHeight);
    const canvasSize = Math.min(crop.width * scaleX, screenShotSize);
    canvas.width = canvasSize;
    canvas.height = canvasSize / aspect;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      canvasSize,
      canvasSize / aspect
    );

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error('Canvas is empty');
          return;
        }
        const file = new File([blob], 'avatar.png', {
          type: 'image/png',
          lastModified: Date.now(),
        });
        cropFromBlob(blob);
        resolve(file);
      });
    });
  }
};

export function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1]);
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

const SystemPhotoField = forwardRef((props, ref) => {
  const { field: f, data: initial, errors, onChange, cropFromBlob } = props;
  let imageRef = useRef();
  const inputRef = useRef();
  const theme = useTheme();
  const appContext = useContext(AppContext);
  const meetupContext = useContext(MeetupContext);
  const classes = useStyles();
  const { visitorId } = useParams();
  const regFormData = useContext(RegFormContext)

  const [error, setError] = useState(undefined);
  const [croppedImageId, setCroppedImageId] = useState();
  const [imageId, setImageId] = useState(initial['photo']);
  const [imageSrc, setImageSrc] = useState();
  const [webCam, setWebCam] = useState(false);
  const aspect = f.photoProportion === '3x4' ? 3./4. : 1;
  const [crop, setCrop] = useState({
    aspect: aspect,
  });


  const onImageLoaded = useCallback(image => {
    imageRef.current = image;

    const k = image.height / image.naturalHeight;
    const cropSize = Math.min(image.height, image.width, k * screenShotSize);

    const y = (image.height - cropSize / aspect) / 2;
    const x = (image.width - cropSize) / 2;

    const initialCrop = {
      width: cropSize,
      height: cropSize / aspect,
      x,
      y,
      aspect: aspect,
    }

    getCrop(imageRef.current, initialCrop, aspect, cropFromBlob)
    setCrop(initialCrop);
    

    return false;
  }, []);

  const validate = () => {
    if (f.required && !imageSrc && !imageId) {
      setError('Обязательное поле');
      return false;
    }
    setError(undefined);
    return true;
  };

  useImperativeHandle(ref, () => ({
    fillValue: async data => {
      if (imageSrc) {
        const file = await getCrop(imageRef.current, crop, aspect, cropFromBlob);
        let token = null
        if (regFormData.regForm) {
          token = regFormData.regForm.token
        }
        await appContext.imageApi.uploadImage(meetupContext.meetupId, undefined, token, file).then(res => {
          data['photo'] = res.data.imageId;
        });
      } else {
        data['photo'] = imageId;
      }
      return validate();
    },
  }));

  const showWebCam = () => {
    setWebCam(true);
  };

  const closeWebCam = () => {
    setWebCam(false);
  };

  const upload = e => {
    e.preventDefault();
    document.getElementById('raised-button-file').click();
  };

  const uploadFile = e => {
    const file = e.target.files[0];
    setImageSrc(window.URL.createObjectURL(file));
  };

  const clear = e => {
    setImageId(undefined);
    setImageSrc(undefined);
  };

  const webcamRef = React.useRef(null);

  const takePhoto = () => {
    const sc = webcamRef.current.getScreenshot({ width: screenShotSize, height: screenShotSize / aspect });
    const file = new Blob([dataURItoBlob(sc)], { type: 'image/png' });
    setImageSrc(window.URL.createObjectURL(file));
    closeWebCam();
  };

  const videoConstraints = {
    width: screenShotSize,
    height: screenShotSize / aspect,
    facingMode: 'user',
  };

  return (
    <Grid container spacing={2}>

      <Grid item xs={8}>
        {imageSrc || imageId ? (
          <div style={{}}>
            {imageId && !imageSrc ? (
              <>
                <img src={'/api/image/' + imageId} className={classes.image} />
                <br />
                <Typography variant={'caption'}>{imageId}</Typography>
              </>
            ) : (
              <ReactCrop
                alt='Preview'
                className={classes.image}
                src={imageSrc}
                crop={crop}
                onChange={newCrop => setCrop(newCrop)}
                onImageLoaded={onImageLoaded}
                onComplete={newCrop => getCrop(imageRef.current, newCrop, aspect, cropFromBlob) }
                imageStyle={{
                  objectFit: 'contain',
                  maxWidth: 300,
                  maxHeight: 300,
                }}
              />
            )}
          </div>
        ) : (
          <Typography>Нет фото</Typography>
        )}
        {!!error && <Typography color={'error'}>{error}</Typography>}
      </Grid>
      <Grid item xs={4}>
        <Button color='primary' variant='contained' fullWidth onClick={upload}>
          Загрузить
        </Button>
        <div className={classes.divider} />
        <Button color='primary' variant='contained' fullWidth onClick={showWebCam}>
          Снять
        </Button>
        <div className={classes.divider} />
        <Button color='primary' variant='contained' fullWidth disabled={!imageSrc && !imageId} onClick={clear}>
          Удалить
        </Button>
      </Grid>

      <input style={{ display: 'none' }} id='raised-button-file' type='file' onChange={uploadFile} />

      <Modal
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={webCam}
        onClose={closeWebCam}
        className={classes.modal}
      >
        <div className={classes.paper} style={{ width: 500 }}>
          <h2 id='simple-modal-title'>Снять фото</h2>

          <p
            id='simple-modal-description'
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Webcam
              audio={false}
              width={screenShotSize}
              height={screenShotSize / aspect}
              mirrored={true}
              ref={webcamRef}
              screenshotFormat='image/png'
              videoConstraints={videoConstraints}
            />
          </p>

          <Button color='primary' variant='contained' onClick={takePhoto}>
            Щёлк
          </Button>
        </div>
      </Modal>
    </Grid>
  );
});

export default SystemPhotoField;
