import React, {useContext, useState} from 'react';
import { useParams } from 'react-router-dom';
import ModalDialog from '../../../components/ModalDialog/ModalDialog';
import { AppContext } from '../../../AppContext';
import MailingEditForm from './MailingEditForm';
import { useForm } from 'react-hook-form';
import { errorsToForm } from '../../../components/Forms/form_utils';
import {fixDotProperties} from "../../Merch/components/MerchEdit";

const MailingAdd = props => {
  const { goBack } = props;
  const appContext = useContext(AppContext);
  const { mid: meetupId } = useParams();
  const form = useForm();

  const handleSave = () => {
    let data = form.getValues();
    data = fixDotProperties(data);
    data.recipients.tags = data.recipients.tagsStr && data.recipients.tagsStr.split(",") || []
    appContext.mailingApi
      .createMailing(meetupId, data)
      .then(({ data }) => {
        goBack(data);
      })
      .catch(errorsToForm(form));
  };

  return (
      <ModalDialog title={'Создание рассылки'} okButtonText={'Сохранить'} handleOk={handleSave} handleClose={goBack}>
        <MailingEditForm meetupId={meetupId} form={form} type={form.watch("type")} />
      </ModalDialog>
  );
};

MailingAdd.propTypes = {};

export default MailingAdd;
